import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import UserService from '../../services/UserService';
import AuthService from 'services/AuthService';
import { decodeToken } from "react-jwt";
import { initSocket } from '../../services/socketService';
import dayjs from 'dayjs';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null
}

export const signIn = createAsyncThunk('auth/signIn', async (data, { rejectWithValue }) => {
	try {
		const { email, password } = data
		const response = await AuthService.login({ email, password })

		if (!response.token) { return rejectWithValue(response.message) }
		const token = response.token;

		// Decodificar token
		let decodedToken = decodeToken(token);
		localStorage.setItem(AUTH_TOKEN, token);

		// socket.io
		initSocket(decodedToken);

		// Atualizar último acesso do usuário
		UserService.update({
			id: decodedToken.sub,
			email: decodedToken.email,
			lastOnline: dayjs().format('YYYY-MM-DD HH:mm:ss')
		});

		window.location.href = '/'

		return token;
	} catch (err) {
		const msg500 = 'Lamentamos o incómodo. Por favor, tente novamente mais tarde ou contacte o nosso suporte caso o problema persista.'
		if ([`Cannot read properties of undefined (reading 'status')`].includes(err.message)) {
			return rejectWithValue(msg500)
		} else {
			return rejectWithValue(err.message || 'Error')
		}
	}
})

export const signUp = createAsyncThunk('auth/signUp', async (data, { rejectWithValue }) => {
	try {
		data.origin = 'web'
		const { sub, refreshToken, message } = await UserService.create(data)
		if (sub) {
			const token = refreshToken;
			localStorage.setItem(AUTH_TOKEN, refreshToken);
			return token;
		} else {
			return rejectWithValue(message);
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
})

export const signOut = createAsyncThunk('auth/signOut', async () => {
	localStorage.removeItem(AUTH_TOKEN);
	// localStorage.removeItem('labels');
	// localStorage.removeItem('members');
	// localStorage.removeItem('members_details');
	window.location.href = '/auth/login'
})

export const signInWithGoogle = createAsyncThunk('auth/signInWithGoogle', async (_, { rejectWithValue }) => {
	const response = await FirebaseService.signInGoogleRequest()
	if (response.user) {
		const token = response.user.refreshToken;
		localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
		return token;
	} else {
		return rejectWithValue(response.message?.replace('Firebase: ', ''));
	}
})

export const signInWithFacebook = createAsyncThunk('auth/signInWithFacebook', async (_, { rejectWithValue }) => {
	const response = await FirebaseService.signInFacebookRequest()
	if (response.user) {
		const token = response.user.refreshToken;
		localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
		return token;
	} else {
		return rejectWithValue(response.message?.replace('Firebase: ', ''));
	}
})

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			state.redirect = '/'
		},
		showLoading: (state) => {
			state.loading = true
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signUp.pending, (state) => {
				state.loading = true
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signUp.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithGoogle.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithGoogle.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signInWithGoogle.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithFacebook.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithFacebook.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signInWithFacebook.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
	},
})

export const {
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess
} = authSlice.actions

export default authSlice.reducer